import './../index.scss';
import IconArrow from './../uil_location-arrow.svg';

function Header() {
    return (
      <header>
        <div className='left'>
            <div className='icon-arrow'>
                <img src={IconArrow} alt='Icon Arrow' />
            </div>
            <div className='location'>
                Philippines
            </div>
            <div className='timezone'>
                GMT +8
            </div>
        </div>
        <div className='right'>
            <a href='mailto:themonkeygee@gmail.com' className='button'>Contact Me</a>
        </div>
      </header>
    );
}
  
export default Header;